import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import VideoPlayer from "../components/videoPlayer";
import ReactPlayer from "react-player";
import { colorizer } from "../lib/colorizer";
import "../styles/pages/work-template.scss";
gsap.registerPlugin(ScrollTrigger);

const NarrativePostTemplate = ({ data }) => {
  const post = data.contentfulWorkPost;
  const placeholderRef = useRef(null);
  const {
    artDepartment,
    associations,
    bts,
    cameraDepartment,
    cast,
    catering,
    contentful_id,
    coverImage,
    dateReleased,
    departmentHeads,
    description,
    duration,
    leadCast,
    live,
    musicDepartment,
    productionDepartment,
    projectType,
    projectUrl,
    soundDepartment,
    stills,
    title,
    vfxDepartment,
  } = post;

  useEffect(() => {
    if (placeholderRef.current) {
      gsap.fromTo(
        placeholderRef.current,
        { y: -300 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ".work-template__video",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
  }, []);

  return (
    <div className="work-template">
      <section className="work-template__hero">
        <div className="work-template__hero__inner">
          <h1>{title}</h1>
          <p>{description.description}</p>
        </div>
      </section>
      <section className="work-template__video">
        <div className="work-template__video__inner">
          {projectUrl.includes("youtube") && (
            <ReactPlayer
              url={projectUrl}
              controls={true}
              width="100%"
              height="100%"
              className={`w-full h-full cursor-pointer`}
            />
          )}
          {!projectUrl.includes("youtube") && (
            <div className="work-template__video__placeholder">
              <h2>COMING SOON</h2>
              <div
                ref={placeholderRef}
                className="work-template__video__placeholder__image"
              >
                {stills && stills.length > 0 && (
                  <GatsbyImage image={stills[0].gatsbyImageData} alt={title} />
                )}
                {(!stills && (bts && bts.length > 0)) && (
                  <GatsbyImage image={bts[0].gatsbyImageData} alt={title} />
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="work-template__lead-credits">
        <div className="work-template__lead-credits__inner">
          <h2>Credits</h2>
          <div className="work-template__lead-credits__heads">
            <h2>Department Heads</h2>
            <div className="work-template__lead-credits__heads__index">
              {departmentHeads.map((departmentHead) => {
                const { title, description, gatsbyImageData } = departmentHead;
                return (
                  <div className="work-template__lead-credits__heads__item">
                    <p>{description ? description : "N/A"}</p>
                    {gatsbyImageData && (
                      <GatsbyImage image={gatsbyImageData} alt={title} />
                    )}
                    {!gatsbyImageData && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100"
                        height="100"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path
                            stroke="var(--white)"
                            d="M21 12a8.958 8.958 0 0 1-1.526 5.016A8.991 8.991 0 0 1 12 21a8.991 8.991 0 0 1-7.474-3.984A9 9 0 1 1 21 12Z"
                          />
                          <path
                            fill="var(--white)"
                            d="M13.5 9a1.5 1.5 0 0 1-1.5 1.5v1A2.5 2.5 0 0 0 14.5 9zM12 10.5A1.5 1.5 0 0 1 10.5 9h-1a2.5 2.5 0 0 0 2.5 2.5zM10.5 9A1.5 1.5 0 0 1 12 7.5v-1A2.5 2.5 0 0 0 9.5 9zM12 7.5A1.5 1.5 0 0 1 13.5 9h1A2.5 2.5 0 0 0 12 6.5zM5.166 17.856l-.48-.142l-.077.261l.177.207zm13.668 0l.38.326l.177-.207l-.078-.261zM9 15.5h6v-1H9zm0-1a4.502 4.502 0 0 0-4.313 3.214l.958.285A3.502 3.502 0 0 1 9 15.5zm3 6a8.48 8.48 0 0 1-6.455-2.97l-.759.652A9.48 9.48 0 0 0 12 21.5zm3-5a3.502 3.502 0 0 1 3.355 2.5l.958-.286A4.502 4.502 0 0 0 15 14.5zm3.455 2.03A8.48 8.48 0 0 1 12 20.5v1a9.48 9.48 0 0 0 7.214-3.318z"
                          />
                        </g>
                      </svg>
                    )}
                    <h3>{title}</h3>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="work-template__lead-credits__heads">
            <h2>Lead Cast</h2>
            <div className="work-template__lead-credits__heads__index">
              {leadCast.map((castMember) => {
                const { title, description, gatsbyImageData } = castMember;
                return (
                  <div className="work-template__lead-credits__heads__item">
                    <p>{description ? description : "N/A"}</p>
                    {gatsbyImageData && (
                      <GatsbyImage image={gatsbyImageData} alt={title} />
                    )}
                    {!gatsbyImageData && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100"
                        height="100"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path
                            stroke="var(--white)"
                            d="M21 12a8.958 8.958 0 0 1-1.526 5.016A8.991 8.991 0 0 1 12 21a8.991 8.991 0 0 1-7.474-3.984A9 9 0 1 1 21 12Z"
                          />
                          <path
                            fill="var(--white)"
                            d="M13.5 9a1.5 1.5 0 0 1-1.5 1.5v1A2.5 2.5 0 0 0 14.5 9zM12 10.5A1.5 1.5 0 0 1 10.5 9h-1a2.5 2.5 0 0 0 2.5 2.5zM10.5 9A1.5 1.5 0 0 1 12 7.5v-1A2.5 2.5 0 0 0 9.5 9zM12 7.5A1.5 1.5 0 0 1 13.5 9h1A2.5 2.5 0 0 0 12 6.5zM5.166 17.856l-.48-.142l-.077.261l.177.207zm13.668 0l.38.326l.177-.207l-.078-.261zM9 15.5h6v-1H9zm0-1a4.502 4.502 0 0 0-4.313 3.214l.958.285A3.502 3.502 0 0 1 9 15.5zm3 6a8.48 8.48 0 0 1-6.455-2.97l-.759.652A9.48 9.48 0 0 0 12 21.5zm3-5a3.502 3.502 0 0 1 3.355 2.5l.958-.286A4.502 4.502 0 0 0 15 14.5zm3.455 2.03A8.48 8.48 0 0 1 12 20.5v1a9.48 9.48 0 0 0 7.214-3.318z"
                          />
                        </g>
                      </svg>
                    )}
                    <h3>{title}</h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="work-template__credits">
        <div className="work-template__credits__inner">
          <div className="work-template__credits__index">
            {cast && cast.length > 0 && (
              <div className="work-template__credits__department">
                <h3>Cast:</h3>
                <div className="work-template__credits__members">
                  {cast.map((castMember, index) => {
                    const isLast = index === cast.length - 1;
                    return (
                      <span key={index}>
                        {castMember}
                        {!isLast && <span>,&nbsp;</span>}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {cameraDepartment && cameraDepartment.length > 0 && (
              <div className="work-template__credits__department">
                <h3>Camera Department:</h3>
                <div className="work-template__credits__members">
                  {cameraDepartment.map((crewMember, index) => {
                    const isLast = index === cameraDepartment.length - 1;
                    return (
                      <span key={index}>
                        {crewMember}
                        {!isLast && <span>,&nbsp;</span>}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {artDepartment && artDepartment.length > 0 && (
              <div className="work-template__credits__department">
                <h3>Art Department:</h3>
                <div className="work-template__credits__members">
                  {artDepartment.map((crewMember, index) => {
                    const isLast = index === artDepartment.length - 1;
                    return (
                      <span key={index}>
                        {crewMember}
                        {!isLast && <span>,&nbsp;</span>}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {soundDepartment && soundDepartment.length > 0 && (
              <div className="work-template__credits__department">
                <h3>Sound Department:</h3>
                <div className="work-template__credits__members">
                  {soundDepartment.map((crewMember, index) => {
                    const isLast = index === soundDepartment.length - 1;
                    return (
                      <span key={index}>
                        {crewMember}
                        {!isLast && <span>,&nbsp;</span>}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {vfxDepartment && vfxDepartment.length > 0 && (
              <div className="work-template__credits__department">
                <h3>Visual Effects Department:</h3>
                <div className="work-template__credits__members">
                  {vfxDepartment.map((crewMember, index) => {
                    const isLast = index === vfxDepartment.length - 1;
                    return (
                      <span key={index}>
                        {crewMember}
                        {!isLast && <span>,&nbsp;</span>}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {musicDepartment && musicDepartment.length > 0 && (
              <div className="work-template__credits__department">
                <h3>Music Department:</h3>
                <div className="work-template__credits__members">
                  {musicDepartment.map((crewMember, index) => {
                    const isLast = index === musicDepartment.length - 1;
                    return (
                      <span key={index}>
                        {crewMember}
                        {!isLast && <span>,&nbsp;</span>}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {productionDepartment && productionDepartment.length > 0 && (
              <div className="work-template__credits__department">
                <h3>Production Department:</h3>
                <div className="work-template__credits__members">
                  {productionDepartment.map((crewMember, index) => {
                    const isLast = index === productionDepartment.length - 1;
                    return (
                      <span key={index}>
                        {crewMember}
                        {!isLast && <span>,&nbsp;</span>}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default NarrativePostTemplate;

export const query = graphql`
  query ($id: String!) {
    contentfulWorkPost(contentful_id: { eq: $id }) {
      description {
        description
      }
      musicDepartment
      productionDepartment
      projectUrl
      projectType
      soundDepartment
      stills {
        gatsbyImageData(width: 1200, placeholder: DOMINANT_COLOR, quality: 80)
      }
      vfxDepartment
      title
      live
      coverImage {
        gatsbyImageData(width: 420, placeholder: DOMINANT_COLOR, quality: 80)
      }
      contentful_id
      artDepartment
      associations
      cameraDepartment
      cast
      catering
      bts {
        gatsbyImageData(width: 1200, placeholder: DOMINANT_COLOR, quality: 80)
      }
      departmentHeads {
        gatsbyImageData(width: 100, quality: 100, placeholder: DOMINANT_COLOR)
        filename
        description
        title
      }
      leadCast {
        filename
        title
        description
        gatsbyImageData(width: 100, quality: 90, placeholder: DOMINANT_COLOR)
      }
      dateReleased
      duration
    }
  }
`;
